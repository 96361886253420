var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("picture", [
    _c("source", {
      attrs: {
        srcset: "/static/images/" + _vm.name + ".webp",
        type: "image/webp",
      },
    }),
    _vm._v(" "),
    _c("source", {
      attrs: {
        srcset: "/static/images/" + _vm.name + ".png",
        type: "image/png",
      },
    }),
    _vm._v(" "),
    _c("img", {
      attrs: {
        src: "/static/images/" + _vm.name + ".png",
        width: _vm.width,
        height: _vm.height,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }