var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tooltip",
    {
      attrs: {
        label: _vm.label,
        position: "is-right",
        type: _vm.isSuccess ? "is-success" : "is-dark",
        active: _vm.hasPermission,
        delay: 100,
        always: _vm.forceTooltip,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "tag is-light",
          class: { enabled: _vm.hasPermission },
          on: {
            click: function ($event) {
              return _vm.writeToClipboard($event)
            },
          },
        },
        [
          _c("i", { staticClass: "fas fa-hashtag mr-1" }),
          _vm._v("\n    " + _vm._s(_vm.value.substr(1)) + "\n  "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }