var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box", class: { "still-loading": _vm.loading } },
    [
      _c("article", { staticClass: "columns is-vcentered" }, [
        _c(
          "div",
          { staticClass: "column is-narrow has-text-centered-mobile" },
          [
            _c("img", {
              attrs: { src: _vm.clan.badgeUrls.medium, width: "128" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "columns is-vcentered" }, [
              _c("div", { staticClass: "column is-narrow" }, [
                _c("h3", { staticClass: "title is-3 is-marginless" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.clan.name) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-narrow mt-1" }, [
                _c(
                  "div",
                  { staticClass: "tags is-centered" },
                  [
                    _c("clan-tag", { attrs: { value: _vm.clan.tag } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "tag is-light" }, [
                      _c("i", { staticClass: "fas fa-user-friends mr-1" }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.clan.members) +
                          "\n              "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "content is-medium" }, [
              _vm._v(_vm._s(_vm.clan.description)),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }