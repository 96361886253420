var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { on: { reset: _vm.onReset } },
    [
      _vm.savedTag
        ? [
            _c("section", { staticClass: "hero" }, [
              _c(
                "div",
                { staticClass: "hero-body has-text-centered-mobile" },
                [
                  _c("card", {
                    attrs: { tag: _vm.savedTag, "found-clan": _vm.fetchedClan },
                    on: {
                      error: _vm.onClanError,
                      "update:foundClan": function ($event) {
                        _vm.fetchedClan = $event
                      },
                      "update:found-clan": function ($event) {
                        _vm.fetchedClan = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-warning is-large",
                        attrs: { type: "reset" },
                      },
                      [_vm._v("Reset")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "button is-success is-large",
                        attrs: {
                          href:
                            "/clan/" +
                            (_vm.foundClan ? _vm.foundClan.slug : ""),
                          disabled: _vm.foundClan == null,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.foundClan ? _vm.foundClan.name : "Your Clan"
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.hasUser
                      ? _c(
                          "a",
                          {
                            staticClass: "button is-info is-large",
                            attrs: {
                              href: "/player/" + _vm.userSlug,
                              disabled: _vm.userSlug == null,
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { pack: "fas", icon: "user" },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Your Profile")]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            !_vm.skipPlayerQuestion && _vm.fetchedClan && !_vm.hasUser
              ? _c(
                  "b-modal",
                  {
                    attrs: { active: _vm.showModal, "has-modal-card": "" },
                    on: {
                      "update:active": function ($event) {
                        _vm.showModal = $event
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-card" }, [
                      _c("header", { staticClass: "modal-card-head" }),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "modal-card-body" },
                        [
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _c("h3", { staticClass: "subtitle is-3" }, [
                                _vm._v("Chief, tell me who you are!"),
                              ]),
                              _vm._v("\n              I found "),
                              _c("b", [
                                _vm._v(_vm._s(_vm.fetchedClan.players.length)),
                              ]),
                              _vm._v(" players in "),
                              _c("b", [_vm._v(_vm._s(_vm.fetchedClan.name))]),
                              _vm._v(
                                ". If you are one of these players, then I can remember next time. I will make suggestions or compare you against other players. This optional\n              so if you don't me to remember just skip it. You can always manage your profile later.\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "column is-narrow is-hidden-mobile",
                              },
                              [
                                _c("web-p-image", {
                                  attrs: { name: "builder-show", width: "200" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("player-list", {
                            attrs: { players: _vm.fetchedClan.players },
                            on: {
                              "update:selectedPlayer": _vm.onPlayerSelected,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("footer", { staticClass: "modal-card-foot" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-warning",
                            attrs: { type: "button" },
                            on: { click: _vm.onSkip },
                          },
                          [_vm._v("Skip this step")]
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        : [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "column field" }, [
              _c(
                "p",
                { staticClass: "control" },
                [
                  _c("search-box", {
                    attrs: { size: "is-large" },
                    on: { "update:selectedClan": _vm.setSavedClan },
                  }),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "hero" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c("h1", { staticClass: "title is-1" }, [_vm._v("Hey, Chief!")]),
        _vm._v(" "),
        _c("h2", { staticClass: "subtitle" }, [
          _vm._v(
            "\n          Welcome to Clash Leaders. This website shows trending clans in Clash of Clans game. Clan achievements can be exported to a spreadsheet or compared\n          to historical data over time. Let's start by finding your clan first.\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }