var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "columns is-multiline is-gapless" },
    _vm._l(_vm.players, function (player) {
      return _c("li", { key: player.tag, staticClass: "column is-4" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.pickPlayer(player)
              },
            },
          },
          [
            _c("span", { staticClass: "trophies is-pulled-right" }, [
              _c("i", {
                staticClass: "fa fa-trophy",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" " + _vm._s(player.trophies.toLocaleString()) + " "),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "name has-text-weight-semibold" }, [
              _vm._v(_vm._s(player.name)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "small",
              { staticClass: "player-tag is-size-7 has-text-weight-light" },
              [_vm._v(_vm._s(player.tag))]
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }